<template>
<!-- 桥梁概况 -->
    <div id="survey">
        <div class="div box box_column">
            <div class="box justify main">
                <div class="box box_column" style="width:50%">
                    <div class="box center mB20">
                        <div class="divBox1">桥梁名称</div>
                        <el-input  placeholder="" v-model="name" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">跨桥组合</div>
                        <el-input  placeholder="" v-model="bridgeAcross" class="line" readonly style="width:50%"></el-input>
                    </div>
                     <div class="box center mB20">
                        <div class="divBox1">桥面宽度</div>
                        <el-input  placeholder="" v-model="bridgeWidth" class="line" readonly style="width:50%"></el-input>
                    </div>
                     <div class="box center mB20">
                        <div class="divBox1">竖曲线半径</div>
                        <el-input  placeholder="" v-model="radius" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">抗震等级</div>
                        <el-input  placeholder="" v-model="antiseismicLevel" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">通航等级</div>
                        <el-input  placeholder="" v-model="navigationLevel" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥梁全长</div>
                        <el-input  placeholder="" v-model="bridgeLength" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">上部结构</div>
                        <el-input  placeholder="" v-model="superstructure" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <!-- 设计风速 -->
                        <div class="divBox1">下部结构</div>
                        <el-input  placeholder="" v-model="substruction" class="line" readonly style="width:50%"></el-input>
                    </div>
                     <div class="box center mB20">
                        <div class="divBox1">设计荷载</div>
                        <el-input  placeholder="" v-model="designLoad" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥面横坡</div>
                        <el-input  placeholder="" v-model="crossSlope" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">设计洪水频率</div>
                        <el-input  placeholder="" v-model="floodFrequency" class="line" readonly style="width:50%"></el-input>
                    </div>
                </div>
                <!-- 右 -->
                <div class="box box_column" style="width:50%">
                    <div class="box center mB20">
                        <div class="divBox1">线路名称</div>
                        <el-input  placeholder="" v-model="lineName" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">高速类型</div>
                        <el-input  placeholder="" v-model="highSpeedType" class="line" readonly style="width:50%"></el-input>
                    </div>
                     <div class="box center mB20">
                        <div class="divBox1">所在地</div>
                        <el-input  placeholder="" v-model="provinceName" class="line" readonly style="width:50%"></el-input>
                    </div>
                     <div class="box center mB20">
                        <div class="divBox1">养护桩号</div>
                        <el-input  placeholder="" v-model="maintainPile" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥梁性质</div>
                        <el-input  placeholder="" v-model="nature" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">主桥上部结构</div>
                        <el-input  placeholder="" v-model="mainSuperstructure" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥墩类型</div>
                        <el-input  placeholder="" v-model="piersType" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥台类型</div>
                        <el-input  placeholder="" v-model="abutmentType" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">支座类型</div>
                        <el-input  placeholder="" v-model="bearingType" class="line" readonly style="width:50%"></el-input>
                    </div>
                     <div class="box center mB20">
                        <div class="divBox1">桥墩基础类型</div>
                        <el-input  placeholder="" v-model="piersBaseType" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥台基础类型</div>
                        <el-input  placeholder="" v-model="abutmentBaseType" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">桥面铺装类型</div>
                        <el-input  placeholder="" v-model="bridgeDeckPavementType" class="line" readonly style="width:50%"></el-input>
                    </div>
                    <div class="box center mB20">
                        <div class="divBox1">竣工时间</div>
                        <el-input  placeholder="" v-model="completionTime" class="line" readonly style="width:50%"></el-input>
                    </div>
                </div>
       

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                demo:'',
                imgs:[],
                projectId:'',
                input:'',
               imgSrc: require('../../assets/images/map/img2.jpg'),

               name:'',  //桥梁名称
               bridgeAcross:'',   //桥跨组合
               bridgeWidth:'',    //桥面宽度
               radius:'',        //竖曲线半径
               antiseismicLevel:'',    //抗震等级
               navigationLevel:'',  //通航等级
               bridgeLength:'',    //桥梁全长
               superstructure:'',   //上部结构
               substruction:'',   //下部结构
               designLoad:'',   //设计荷载
               crossSlope:'',   //桥面横坡
               floodFrequency:'',  //设计洪水频率

                lineName:'',  //线路名称
                highSpeedType:'', //高速类型
                provinceName:'', //所在地
                maintainPile:'',  //养护桩号
                nature:'',  //桥梁性质
                mainSuperstructure:'', // 主桥上部结构
                piersType:'', //桥墩类型
                abutmentType:'', //桥台类型
                bearingType:'', //支座类型
                piersBaseType:'', //桥墩基础类型
                abutmentBaseType:'', //桥台基础类型
                bridgeDeckPavementType:'', //桥面铺装类型
                completionTime:'', //竣工时间
            }
        },
        created () {
            this.projectId = sessionStorage.getItem('projectId');
            this.survey();
            this.imgData();
        },
        mounted () {
              
        },
        methods: {
            survey() {
                   this.$axios.get(`${this.baseURL}base/baseProject/${this.projectId}`)
                    .then(res=>{
                        console.log('简介',res)
                        var data = res.data.data;
                        this.name = data.name ==null?'': data.name;
                        this.bridgeAcross = data.bridgeAcross ==null?'': data.bridgeAcross;
                        this.bridgeWidth = data.bridgeWidth ==null?'': data.bridgeWidth;
                        this.radius = data.radius ==null?'': data.radius;
                        this.antiseismicLevel = data.antiseismicLevel ==null?'': data.antiseismicLevel;
                        this.navigationLevel = data.navigationLevel ==null?'': data.navigationLevel;
                        this.bridgeLength = data.bridgeLength ==null?'':data.bridgeLength;
                        this.superstructure = data.superstructure ==null?'':data.superstructure;
                        this.crossSlope = data.crossSlope ==null?'': data.crossSlope;
                        this.floodFrequency = data.floodFrequency ==null?'':data.floodFrequency;
                        this.windSpeed = data.windSpeed ==null?'': data.windSpeed;
                        this.designLoad = data.designLoad ==null?'': data.designLoad;
                        this.substruction = data.substruction ==null?'': data.substruction;

                        this.lineName = data.lineName==null?'':data.lineName;
                        this.highSpeedType = data.highSpeedType == null?'' : data.highSpeedType;
                        this.provinceName = data.provinceName == null?'' : data.provinceName;
                        this.maintainPile = data.maintainPile == null?'' : data.maintainPile;                    //养护桩号
                        this.nature = data.nature == null?'' : data.nature;                            //桥梁性质
                        this.mainSuperstructure = data.mainSuperstructure == null?'' : data.mainSuperstructure;               // 主桥上部结构
                        this.piersType = data.piersType == null?'' : data.piersType;                        //桥墩类型
                        this.abutmentType = data.abutmentType == null?'' : data.abutmentType;                  //桥台类型
                        this.bearingType = data.bearingType == null?'' : data.bearingType;                       //支座类型
                        this.piersBaseType = data.piersBaseType == null?'' : data.piersBaseType;                     //桥墩基础类型
                        this.abutmentBaseType = data.abutmentBaseType == null?'' : data.abutmentBaseType;                  //桥台基础类型
                        this.bridgeDeckPavementType = data.bridgeDeckPavementType == null?'' : data.bridgeDeckPavementType; //桥面铺装类型
                        // this.completionTime = data.completionTime == null?'': data.completionTime +'年';                //竣工时间

                        if(data.completionTime == null){
                             this.completionTime = '';
                        } else if(data.completionTime == ''){
                             this.completionTime = '';
                        }
                        else{
                              this.completionTime = data.completionTime + '年'; //竣工时间
                        }

                        
                    })  
                    .catch(err=>{
                });
            },

            //获取项目图片
            imgData(){
                this.$axios.get(`${this.baseURL}sys/sysImages/cover/${this.projectId}`)
                    .then(res=>{
                        console.log('图片',res)
                        // this.imgsrc = res.data;
                        // var arr = [];
                        this.imgs = [];
                        for(var i=0;i<res.data.data.length;i++){
                            this.imgs.push(`${this.baseURL}sys/sysImages/img/`+res.data.data[i].id)
                        } 
                        console.log('lenght',this.imgs)
                        if(this.imgs.length == 0){
                            var img =  require('../../assets/images/map/img1.jpg')
                            this.imgs.push(img);
                            console.log('imgs',this.imgs)
                        }

                    })  
                    .catch(err=>{
                });
            },




        },
    }
</script>

<style lang="scss" scoped>
    .img{
        display: block;
        height: 100%;
    }
    .divBox{
        width: 100%;
        padding: 17px;
        box-shadow: 0px -1px 4px 0px rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        // opacity: 0.32;
        border: 1px solid #5E5E5F;
    }
    #survey{
        width: 100%;
        height: 100%;
    }
    .main{
        margin-top: 1%;
        // width: 100%;
        margin-left: 69px;
    }
    .text{
        height: 38px;
        // width: 250px;
        display: inline-block;
        text-align: right;
        line-height: 38px;
        margin-right: 15%;
        margin-bottom: 1%;
        // border: 1px solid #ccc;
    }
    .line{
        // margin-bottom: 1%;
    }
    ::v-deep .el-input{
        width:80%;
        height: 38px;
    }
    .titleImg{
        width: 200px;
        height: 200px;
        // border: 1px solid #ccc;
    }

    .div{
        width: 100%;
         height: 100%;
        // border: 1px solid #000;
    }
    .div1{
        width: 30%;
         border: 1px solid red;
    }
    .div2{
        width: 30%;
          border: 1px solid blue;
    }
    .div3{
        width: 40%;
        border: 1px solid yellow ;
    }

    ::v-deep .el-input__inner{
        // border: 1px solid #4c5a14 !important;
        background: rgba(48, 56, 58, 0.59) !important;
    }
    ::v-deep .el-carousel{
        width: 100%;
        height: 100%;
    }
    ::v-deep .el-carousel__container{
        height: 100%;
        position: relative;
    }
    ::v-deep  .el-carousel__item  img{
        vertical-align: middle;
        text-align:center;
        position: relative;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // // height: 100%;
        // display: block;
        // margin: 0 auto;
        // background-repeat:no-repeat;
        // text-align:center;
        // top: -35%;

    }
    ::v-deep .el-select .el-input .el-select__caret{
        line-height: 22px !important;
    }
    .divBox1{
        width: 129px;
    }

</style>